@import "../../../styles/variables";

.smart-target-form {
  background-color: $card-background;
  padding: 1.5rem;
}

button.ui.button.smart-target-submit {
  border-radius: 6px;
  padding: 10px 12px 10px 18px;
  color: white;
  background-color: black;
  font-size: 0.875rem;
  margin-left: auto;

  width: 13.75rem;
  height: 2.5rem;

  svg {
    margin-left: auto;
    width: 1rem;
    height: 1rem;
  }
}

.smart-target-input {
  white-space: nowrap;

  .ui.input {
    max-width: 60%;
    margin-right: 0.5rem !important;
  }

  span {
    line-height: 2.8rem;
  }

  .input-prefix {
    position: absolute;
    margin-left: -1rem;
  }
}

button.ui.button.add-filter {
  color: $secondary-text;
  font-size: 14px;
  height: auto;
  width: 6rem;
  padding: 0.25rem 0;

  svg {
    width: 1rem;
    margin-right: 0.75rem;
    stroke: $secondary-text;
  }
}

.ui.form .fields.smart-target-checkboxes {
  gap: 2rem;
  margin: 1rem 0;
}

.chip-filter-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.icon.question.circle {
  margin: 0 1rem;
}
