.fullscreen-email {
	width: 100%;
	display: flex;
	justify-content: center;

	.email-verification {
		position: fixed;
		top: 20%;
	}
}
