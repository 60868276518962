@import "../../../styles/variables";
.pagination-container {
	width: 100%;
	min-width: 800px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100px;

	& > :last-child {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.pagination-button-container {
		display: flex;
		justify-content: space-around;

		button.text {
			padding: 1rem !important;
		}
	}

	.page-counter {
		margin-right: 30px;
	}
	.ui.input.focus > input,
	.ui.input {
		width: fit-content;
	}

	.page-skip {
		margin-right: 30px;
	}

	.page-skip b {
		margin-right: 10px;
	}
}
