@import '../../../styles/variables.scss';

.ui.label {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.75rem;
  background-color: #FFF;
}

.chip-container {
  display: flex;
  gap: 0.5rem;

  span {
    text-wrap: nowrap;
  }

  svg {
    height: 0.75rem;
  }
}

.ui.label.rounded {
  border-radius: 28px;
  padding: 0.375rem 0.625rem;
}

.ui.label.square {
  border-radius: 0;
}

.ui.label.chip-negative {
  color: $error-text;
  background-color: $error;
}

.ui.label.chip-positive {
  color: $complete-text;
  background-color: $complete;
}

.ui.label.chip-neutral {
  color: $progress-text;
  background-color: $progress;
}

.chip-close {
  cursor: pointer;
  margin-left: 0.2rem;

  &:hover {
    opacity: 0.7;
  }
}
