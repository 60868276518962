.ui.segment.filter-item-list-container {
  overflow: scroll;
  height: 20rem;
  padding: 0;
}

.search-icon {
  position: absolute;
  top: 23%;
  left: 1rem;
}

.ui.input.search-measures {
  width: 100%;
  input {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none
  }
}

.button-row {
  .ui.left.attached.button, .ui.right.attached.button {
    display: inline-block;
    margin-top: 0.5rem;
    min-width: 9rem;
    text-align: center;
    font-size: 0.85rem;
  }
}
