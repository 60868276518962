.mini-table {
	width: 100%;

	td {
		max-width: 4rem;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.epc-rating-tile {
		margin: 0 auto;
	}
}
