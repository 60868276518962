.groups-card-container {
	max-width: 600px;
	position: relative;
	margin: 0;

	.groups-card-container-inside {
		display: flex;
		justify-content: space-between;
		padding: 0;
		margin: 0;
		margin-top: 10px;
		align-items: center;
	}

	i.trash {
		visibility: hidden;
	}

	&:hover {
		i.trash {
			visibility: visible;
		}
	}

	&.is-loading {
		pointer-events: none;
		opacity: 0.5;
	}

	.view-table-button-container
	{
		margin: .5rem 0;
		display: flex;
		flex-direction: column;

		gap: .5rem;

		button
		{
			width: 100%;
			gap: 12px;
		}
	}
}

.groups-card-container-inside-button {
	display: flex;
	flex-direction: column;
	min-width: 150px;

	button
	{
		white-space: nowrap;
	}
}

.favourite-button-vector {
	width: auto;

	position: absolute;
	z-index: 999;
	right: 55px;
	top: 0;
}

.group-card-button {
	display: flex;
	align-items: center;
	gap: 12px;
}

.group-description-container p {
	text-align: left;
	margin-top: 8px;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #373a36;
	padding-right: 30px;
	opacity: 1;
}

.card-bottom-row {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	color: #10183d;
}

.confirm-delete-group .delete-confirmation-buttons {
	display: flex;
	justify-content: flex-end;
}
