table.failed-jobs td.exception > div
{
	display: flex;
	justify-content: stretch;
	align-items: center;
}

table.failed-jobs td.exception .error.message
{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 33vw;
	flex: 1;
	margin-bottom: 0 !important;
	margin-right: .5rem !important;
}

table.failed-jobs td.exception i.copy
{
	cursor: pointer;
}