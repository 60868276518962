@import "../../../styles/variables";

.story-checkbox-default {
	&.ui.checkbox {
		overflow: hidden !important;
		label:before {
			border: 1px solid $red !important;
			background-color: white;
		}

		input:checked {
			~ label:after,
			&:focus ~ label:after {
				color: $red;
				border-radius: 4px;
			}
		}
	}
}
.story-epc-rating-grade-a {
	&.ui.checkbox {
		label:before {
			border: 1px solid $epc-rating-grade-a !important;
			background-color: white;
		}

		input:checked {
			~ label:after,
			&:focus ~ label:after {
				background-color: $epc-rating-grade-a;
				color: white;
				border-radius: 4px;
				width: 20px;
				height: 20px;
				padding-left: 0px;
			}
		}
	}
}

.story-epc-rating-grade-b {
	&.ui.checkbox {
		label:before {
			border: 1px solid $epc-rating-grade-b !important;
			background-color: white;
			width: 20px;
			height: 20px;
		}

		input:checked {
			~ label:after,
			&:focus ~ label:after {
				background-color: $epc-rating-grade-b;
				color: white;
				border-radius: 4px;
				width: 20px;
				height: 20px;
				padding-left: 0px;
			}
		}
	}
}

.story-epc-rating-grade-c {
	&.ui.checkbox {
		label:before {
			border: 1px solid $epc-rating-grade-c !important;
			background-color: white;
		}

		input:checked {
			~ label:after,
			&:focus ~ label:after {
				background-color: $epc-rating-grade-c;
				color: white;
				border-radius: 4px;
				width: 20px;
				height: 20px;
				padding-left: 0px;
			}
		}
	}
}

.story-epc-rating-grade-e {
	&.ui.checkbox {
		label:before {
			border: 1px solid $epc-rating-grade-e !important;
			background-color: white;
		}

		input:checked {
			~ label:after,
			&:focus ~ label:after {
				background-color: $epc-rating-grade-e;
				color: white;
				border-radius: 4px;
				width: 20px;
				height: 20px;
				padding-left: 0px;
			}
		}
	}
}

.story-epc-rating-grade-d {
	&.ui.checkbox {
		label:before {
			border: 1px solid $epc-rating-grade-d !important;
			background-color: white;
		}

		input:checked {
			~ label:after,
			&:focus ~ label:after {
				background-color: $epc-rating-grade-d;
				color: white;
				border-radius: 4px;
				width: 20px;
				height: 20px;
				padding-left: 0px;
			}
		}
	}
}

.story-epc-rating-grade-g {
	&.ui.checkbox {
		label:before {
			border: 1px solid $epc-rating-grade-g !important;
			background-color: white;
		}

		input:checked {
			~ label:after,
			&:focus ~ label:after {
				background-color: $epc-rating-grade-g;
				color: white;
				border-radius: 4px;
				width: 20px;
				height: 20px;
				padding-left: 0px;
			}
		}
	}
}
.story-epc-rating-grade-f {
	&.ui.checkbox {
		label:before {
			border: 1px solid $epc-rating-grade-f !important;
			background-color: white;
		}

		input:checked {
			~ label:after,
			&:focus ~ label:after {
				background-color: $epc-rating-grade-f;
				color: white;
				border-radius: 4px;
				width: 20px;
				height: 20px;
				padding-left: 0px;
			}
		}
	}
}

.ui.checkbox.checked input:checked ~ label:before {
	background-color: $primary;
	border: none;
}

.ui.checkbox input:focus ~ label:after {
	color: white !important;
}

.ui.checkbox input ~ label:before {
	border: 1px solid black;
	width: 20px;
	height: 20px;
}

.ui.checkbox.label-left, .ui.toggle.checkbox.label-left {
	label {
		padding-left: 0;
		padding-right: 4rem;
	}

	input, label:before {
		left:auto;
		right:0;
	}

	label:after {
		left: auto;
		right: 1.75rem;
	}

	input:checked + label:after {
		left: auto;
		right: 0.5rem;
	}
}
