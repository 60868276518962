@import "../../src/styles/variables";

.maintenance-content {
  margin-top: 5rem;
  text-align: center;
  font-family: $font-family-noto;
  color: white;

  .dream-logo-with-text {
    width: 30%;
    margin-bottom: 5rem;
  }

  .ui.huge.header {
    color: $dashboard-background;
    font-size: 3rem;
  }

  p {
    font-size: 1.2rem;
  }
}

.maintenance-page {
  background: $dark-blue;
  min-height: 100vh;
}
