.performance-stats-container {
	display: flex;
	border-bottom: 1px solid #10183d1e;
	justify-content: space-around;
	padding-top: 50px;
	> div {
		display: flex;
		justify-content: center;
	}
}

.performance-stats-label {
	display: flex;

	justify-content: space-around;
	> div {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		text-align: center;
		font-weight: 400;
		font-size: 14px;
		line-height: 19px;

		width: 130px;
	}
}

.performance-stats-container-checkbox {
	display: flex;
	align-items: center;
	justify-content: center;

	> span {
		margin-left: 20px;
		margin-right: 2em;
	}
}
