@import "../../../styles/variables";

.team-select-container.current-team-select {
	display: flex;
	align-items: center;

	:nth-child(3) {
		margin-left: 4px;
	}
}
