.selected-properties-overlay {
	max-height: 66vh !important;

	display: flex;
	flex-direction: column;

	// NB: Override Semantic, higher specificity
	&.ui.fluid.container.card.selected-properties-overlay {
		// max-width: 40% !important;
		// position: absolute;
	}

	.table-container {
		flex: 0;
		overflow-y: scroll;
	}

	.preloader-container {
		margin: 0 185px;
	}
}
