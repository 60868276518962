@import "../../../styles/variables";

.properties-loaded-container {
	display: flex;
	align-items: center;
	max-width: 240px;
	min-width: 240px;
	h4.ui.header {
		line-height: 22px;
		padding-left: 15px;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
	}
	
}


