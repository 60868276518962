$sidebar-width: 290px !default;
$sidebar-collapsed-width: 120px !default;
$header-height: 64px !default;
$footer-height: 64px !default;

// $breakpoint-xs: 480px !default;
// $breakpoint-sm: 576px !default;
// $breakpoint-md: 768px !default;
// $breakpoint-lg: 992px !default;
// $breakpoint-xl: 1200px !default;
// $breakpoint-xxl: 1600px !default;

$breakpoint-xs: 480px !default;
$breakpoint-sm: 480px !default;
$breakpoint-md: 480px !default;
$breakpoint-lg: 480px !default;
$breakpoint-xl: 480px !default;
$breakpoint-xxl: 480px !default;

@mixin break-point {
	position: fixed;
	left: -$sidebar-width;
	height: 100%;
	top: 0;
	z-index: 100;
	&.collapsed {
		left: -$sidebar-collapsed-width;
	}
	&.toggled {
		left: 0;
		~ .overlay {
			display: block;
		}
	}

	~ .main-layout {
		.header-bar {
			width: 100% !important;
			transition: none;
		}
	}
}

$breakpoints: (
	"break-point-xs": $breakpoint-xs,
	"break-point-sm": $breakpoint-sm,
	"break-point-md": $breakpoint-md,
	"break-point-lg": $breakpoint-lg,
	"break-point-xl": $breakpoint-xl,
	"break-point-xxl": $breakpoint-xxl,
);

@mixin break-point-rtl {
	left: auto;
	right: -$sidebar-width;
	&.collapsed {
		left: auto;
		right: -$sidebar-collapsed-width;
	}
	&.toggled {
		left: auto;
		right: 0;
	}
}

@mixin setBreakPoint($name, $value) {
	&.#{$name} {
		@media (max-width: $value) {
			@include break-point;
		}
	}
}

.main-layout {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	flex-grow: 1;

	&.has-sidebar {
		flex-direction: row;
	}
	.header-bar {
		height: $header-height;
		min-height: $header-height;
		position: relative;
	}
	.sidebar {
		width: $sidebar-width;
		max-height: 100vh;
		min-width: $sidebar-width;
		transition: width, left, right, 0.3s;
		&.collapsed {
			width: $sidebar-collapsed-width;
			min-width: $sidebar-collapsed-width;
		}

		@each $key, $value in $breakpoints {
			@include setBreakPoint($key, $value);
		}
	}
	.footer {
		height: $footer-height;
		min-height: $footer-height;
	}
	.content {
		flex-grow: 1;
	}

	.overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(#000, 0.3);
		z-index: 99;
		display: none;
	}

	.sidebar-toggler {
		display: none;

		@each $key, $value in $breakpoints {
			&.#{$key} {
				@media (max-width: $value) {
					display: initial;
				}
			}
		}
	}

	&.fixed-sidebar {
		height: 100%;
		.sidebar {
			height: 100%;
			overflow: auto;
			~ .main-layout {
				height: 100%;
				overflow: auto;
			}
		}
	}

	&.fixed-header {
		.header-bar {
			position: fixed;
			width: 100%;
			z-index: 2;
			~ .main-layout,
			~ .content {
				margin-top: $header-height;
			}
		}
		&.fixed-sidebar {
			.header-bar {
				width: calc(100% - #{$sidebar-width});
			}
			.sidebar.collapsed {
				~ .main-layout {
					.header-bar {
						width: calc(100% - #{$sidebar-collapsed-width});
					}
				}
			}
		}
	}

	&.rtl {
		direction: rtl;
		.sidebar {
			@each $key, $value in $breakpoints {
				&.#{$key} {
					@media (max-width: $value) {
						@include break-point-rtl;
					}
				}
			}
		}
	}
}
