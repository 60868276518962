.csv-file-upload.container {
	padding: 2rem;
	border: 1px dashed grey;
	margin-bottom: 1rem;
}

.csv-file-upload.container ul {
	padding-left: 1rem;
}

.csv-file-upload.container button {
	display: inline;
}
