.ui.grid > .row.map-filterbar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 10px 0px;
	padding-left: 1rem;
	padding-right: 1rem;
	gap: 40px;
}

.checkbox-container {
	display: flex;
	align-items: center;
	gap: 5ch;
	padding: .5rem;
}

label.epc-rating
{
	font-weight: bold;
	padding-left: .5rem;
}

// .filter-container {
// 	display: flex;
// 	align-items: center;
// 	gap: 5ch;
// }

// .filter-container > .two.wide.column {
// 	display: flex;
// }

// .filter-container > .four.wide.column {
// 	display: flex;
// 	align-items: center;
// 	gap: 10px;
// }

// .filter-container > .two.wide.column > .ui.input.story-input--md {
// 	width: 100%;
// }

/*
.filter-container > .two.wide.column > .ui.input.story-input--md > input  {
	background: rgba(255, 255, 255, 0.1);
    border: 2px solid #FF5F43;
    border-radius: 8px;
}
*/

// .toggle-container {
// 	gap: 50px;
// 	display: flex;
// }

.ui.checkbox.toggle-switch input.hidden + label {
	padding-left: 60px;
}

.ui.grid > .row.toggle-row {
	justify-content: flex-end;
	display: flex;
	margin-top: -60px;
}

.map-container {
	.overlays {
		position: absolute;

		bottom: 1rem;
		left: 1rem;

		max-height: calc(100% - 4rem);
		overflow-y: scroll;

		max-width: 50%;
	}
}

form.ui.mapping-filters
{
	display: flex;
	flex-wrap: wrap;

	.field
	{
		flex-basis: 25%;
		padding: .5rem;
	}
}

.mapping-accordion
{
	margin-bottom: 1rem;

	.ui.toggle.checkbox
	{
		padding-left: 1rem;
	}
}