.map-container {
	position: relative;
	border-radius: 25px;
	height: 80vh;
	overflow: hidden;

	& > .preloader-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.67);
	}
}
