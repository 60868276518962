@import "../../../styles/variables.scss";

$tile-size: 40px;

.epc-rating-tile {
	width: $tile-size;
	height: $tile-size;
	line-height: $tile-size;
	text-align: center;
	font-weight: bold;
	border-radius: $rounded-border-small;
}

.epc-rating-tile.compact {
	width: 17px;
	height: 17px;
	border-radius: 3px;
}

.epc-rating-tile[data-grade="A"] {
	background-color: $epc-rating-grade-a;
}

.epc-rating-tile[data-grade="B"] {
	background-color: $epc-rating-grade-b;
}

.epc-rating-tile[data-grade="C"] {
	background-color: $epc-rating-grade-c;
}

.epc-rating-tile[data-grade="D"] {
	background-color: $epc-rating-grade-d;
}

.epc-rating-tile[data-grade="E"] {
	background-color: $epc-rating-grade-e;
}

.epc-rating-tile[data-grade="F"] {
	background-color: $epc-rating-grade-f;
}

.epc-rating-tile[data-grade="G"] {
	background-color: $epc-rating-grade-g;
}

.epc-rating-tile[data-grade="Ungraded"] {
	background-color: $epc-rating-ungraded;
}
