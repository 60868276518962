.net-zero-journey {
	.chart-container {
		position: relative;
		height: 300px;
	}

	.net-zero-journey-chart {
		position: absolute;
		top: 0;
		left: 0;
		height: 300px;
		width: 100%;
	}
}

.net-zero-journey .standards button {
	font-size: 0.8rem;
	padding: 0.5rem;
	width: 100%;
	justify-content: center;
}
