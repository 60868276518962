dl.hovered-layer-features-info {
	display: grid; /* grid container */
	grid-gap: 10px; /* row and column gap */
	grid-auto-flow: dense;

	dt {
		grid-column: 1;
		font-weight: bold;
	}

	dd {
		grid-column: 2;
	}
}

dl.hovered-layer-features-info.deprevation-style {
	grid-template-columns: repeat(2, min-content max-content);
}
