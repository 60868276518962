@import "../../../styles/variables";

.main-footer-container {
	display: flex;
	justify-content: flex-end;
	padding-right: 80px;
	height: 60px;
	padding-bottom: 10px;
	align-items: center;
	margin: 0;
	> span {
		padding-right: 30px;
		color: $dark-blue;
		opacity: 0.8;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
	}
}

.img-footer-logo {
	height: 26px;
}
