ul.column-group-checkboxes {
	list-style: none;
	display: flex;
	padding-left: 0;
	margin-top: 30px;
}

ul.column-group-checkboxes > li {
	padding-right: 3ch;
}

ul.column-group-checkboxes.data-filter > .ui.checkbox input:checked ~ .box:before,
	ul.column-group-checkboxes .ui.checkbox input:checked ~ label:before {
	background-color: #ff5f43;
	border: 1px solid #ff5f43;
	width: 20px;
	height: 20px;
}

ul.column-group-checkboxes.data-filter > .ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
	color: white;
	padding-top: 1px;
	padding-left: 3px;
}

.ui.checkbox input.hidden + label {
	padding-top: 1px;
	padding-left: 35px;
}

ul.column-group-checkboxes {
	gap: 1vw;
}

ul.column-group-checkboxes.data-filter > .ui.checkbox .box:before {
	background-color: transparent;
	width: 20px;
	height: 20px;
	border: #ff5f43 1.5px solid;
}
