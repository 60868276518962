@import "semantic-ui-css/semantic.min.css";
@import "./styles/variables.scss";
@import "./styles/semantic-ui-overrides.scss";
@import "./styles/global.scss";
* {
	padding: 0;
	margin: 0px;
}

html {
	font-size: 16px;
}

@media only screen and (max-width: 1280px) {
	html {
		font-size: 19px;
	}
}

/* @media only screen and (min-width: 370px) {
    html {
        font-size: 8
		
		
		px;
    }
}
  @media only screen and (min-width: 720px) {
    html {
        font-size: 10px;
    }
}

@media only screen and (min-width: 960px) {
    html {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1240px) {
    html {
        font-size: 16px;
    }
}*/

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

body {
	background: #e5e5e5;
}

#root {
	display: flex;
}
