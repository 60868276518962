@import "../../../styles/variables.scss";

.your-spending-chart-legend {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	color: $dark-blue;

	> div {
		display: flex;
		margin-bottom: 20px;
		font-weight: 400;
		line-height: 19px;
		justify-content: space-between;
		height: 30px;
		> div {
			display: flex;
			align-items: center;
			> span {
				margin-left: 20px;
				margin-right: 2em;
			}
		}
	}

	.dashed-line-swatch {
		width: 17px;
		height: 17px;

		background-color: #000000;
		background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
	}
}
