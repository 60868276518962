@import "../../../styles/variables";

.ui.form .field > .current-value-field-label {
  margin-top: 1rem;
}

.ui.form .field > .current-value-field {
  margin-left: 1rem;
  font-size: 1.5rem;
}

.validation-error {
  color: $dark-red;
}

.result-light {
  font-weight: lighter;
}

.ui.table td.actions-dropdown  {
  text-align: end;
}

.smart-target-item-row:hover {
  background-color: #E5E5E5;
  cursor: pointer;
}
