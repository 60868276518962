.datatable-container {
	position: relative;
	overflow: hidden;
	overflow-x: scroll;
	// margin-top: 30px;
	background-color: #ffffff;
	display: grid;
	margin-bottom: 2rem;

	.column-header {
		font-weight: 700;
		font-size: 16px;
		line-height: 22px;
		height: 52px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	> table {
		background-color: #ffffff;
		border-spacing: 0px;
		border: 1px solid #edeef1;
		border-radius: 16px 16px 0 0;
		overflow-y: hidden;
		overflow-x: auto;
		display: block;
	}
}

.page-skip .ui.input {
	width: 4rem !important;
}

.datatable-group-buttons {
	display: flex;
	flex-wrap: wrap;
	gap: 1ch;
	margin-top: 30px;
	> .ui.button.outlined {
		opacity: 0.3;
	}
}

.datatable-column-tr {
	height: 60px;
}

.datatable-column-th {
	display: table-cell;
	vertical-align: top;
	border: 1px solid #edeef1;
	background-color: #fafbff;
}

// TODO: Really this should live elsewhere in case we ever want dummy scrollbars somewhere other than a table
.dummy-scrollbar {
	margin-top: 30px;
	overflow-x: scroll;
}

.dummy-scrollbar-inner-width {
	height: 1px;
}

.ui.selection.group-selector {
	border-radius: 10px;

	> .default.text, .dropdown.icon {
		line-height: 32px;
	}
}
