@import "../styles/variables.scss";

#page.property {
	& .charts .column {
		flex-basis: 50%;
	}

	.chart-container {
		height: 30vw;
	}

	.chart-container.pie-chart {
		height: 10vw;
	}

	.go-back {
		padding-left: 0;
		color: black;

		svg {
			stroke: white;
		}

		&:hover {
			color: $grey;
		}
	}

	h1.address-header {
		margin: 1.5rem 0;
	}
}
