@import "../../../styles/variables";
.toggle-switch {
	&.ui.toggle.checkbox {
		input:checked,
		input:checked ~ label:before {
			background: $dark-green !important;
		}

		input:focus:checked ~ .box:before,
		input:focus:checked ~ label:before {
			background-color: $dark-green !important;
		}

		input ~ .box:after,
		input ~ label:after {
			top: -6px;
			width: 30px;
			height: 30px;
			left: -10px;
		}
		input:checked ~ label:after {
			background: $green !important;
			left: 15px;
			top: -6px;
			width: 30px;
			height: 30px;
			:hover > & {
				border-radius: 50%;
				box-shadow: 0 0 0 8px rgba(120, 213, 159, 0.14);
			}
		}

		.box:before,
		label:before {
			width: 36px;
			height: 19px;
		}
	}
}

.toggle-switch:not(.variant-secondary) {
	&.ui.toggle.checkbox {
		input:checked,
		input:checked ~ label:before {
			background: $epc-rating-grade-g !important;
		}

		input:focus:checked ~ .box:before,
		input:focus:checked ~ label:before {
			background-color: $epc-rating-grade-g !important;
		}

		input ~ .box:after,
		input ~ label:after {
			top: -6px;
			width: 30px;
			height: 30px;
			left: -10px;
		}
		input:checked ~ label:after {
			background: $epc-rating-grade-f !important;
			left: 15px;
			top: -6px;
			width: 30px;
			height: 30px;
			:hover > & {
				border-radius: 50%;
				box-shadow: 0 0 0 8px #ca3b3111;
			}
		}

		.box:before,
		label:before {
			width: 36px;
			height: 19px;
		}
	}
}

.toggle-switch.variant-secondary {
	&.ui.toggle.checkbox {
		input:checked,
		input:checked ~ label:before {
			background: $epc-rating-grade-e !important;
		}

		input:focus:checked ~ .box:before,
		input:focus:checked ~ label:before {
			background-color: $epc-rating-grade-e !important;
		}

		input ~ .box:after,
		input ~ label:after {
			top: -6px;
			width: 30px;
			height: 30px;
			left: -10px;
		}
		input:checked ~ label:after {
			background: $epc-rating-grade-d !important;
			left: 15px;
			top: -6px;
			width: 30px;
			height: 30px;
			:hover > & {
				border-radius: 50%;
				box-shadow: 0 0 0 8px #f2c04b1a;
			}
		}

		.box:before,
		label:before {
			width: 36px;
			height: 19px;
		}
	}
}
