.pie-chart-container
{
	text-align: center;
	position: relative;
	width: 300px;
	height: 220px;
}

.pie-chart-overlay
{
	position: absolute;
	top: 0;
	right: 10px;
	bottom: 30px;
	left: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	font-size: 40px;
	font-weight: 600;
	color: #10183d;
	/* background: #FFFFFF33; */
	text-align: center;
	pointer-events: none;
}

.pie-chart-total-label
{
	font-size: 16px;
	font-weight: 400;
	margin-top: 10px;
}