.ui.vertically.divided.grid > a {
	margin-top: 20px;
	margin-bottom: 10px;
}

.ui.vertically.padded.grid > .wide.column > .ui.vertically.divided.grid > .row {
	height: max(40px, 80px);
}

.your-properties {
	.column {
		flex-grow: 1 !important;
	}

	.ui.vertically.divided.grid {
		height: 100%;
	}

	.ui.vertically.divided.grid a:last-child {
		display: block;
		width: 100%;
	}

	.ui.vertically.divided.grid a:last-child > button {
		margin: 0 auto;
	}

	.row:not(:first-child) * {
		font-size: 0.7rem;
	}
}
