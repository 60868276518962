@import "../../../styles/variables";

.profile-widget {
	display: flex;
	align-items: center;

	.ui.dropdown > .dropdown.icon:before {
		color: $red;
	}
	div.divider.text {
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 22px;
		color: $dark-blue;
		padding-left: 15px;
	}
	.ui.circular.image {
		width: 40px;
		min-width: 40px;
		height: auto;
	}
}
