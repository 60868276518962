@import "../../../styles/variables";

.team-select-container {
	display: flex;
	height: 22px;
	align-items: center;
	min-width: 310px;
	max-width: 650px;

	:nth-child(1) {
		padding: 0 0 0 5px;
	}

	h4.ui.header {
		padding-left: 15px;
		font-weight: normal;
	}

	h4.ui.disabled.header {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
	}

	.ui.inline.dropdown > .text {
		font-style: normal;
		font-size: 16px;
		color: $primary;
	}

	.ui.dropdown > .dropdown.icon:before {
		color: $grey;
		padding-left: 5px;
	}
}
