$color: #5c8df6;
$colorRight: darken(#5c8df6, 15%);
$colorLeft: darken(#5c8df6, 5%);
$shadow: #dbe3f4;

.preloader-container {
	min-height: 180px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 2rem;

	&.fullscreen {
		position: fixed;
		top: 40%;
		left: 46%;
	}

	&.cover {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.67);
	}

	.status {
		z-index: 2;
	}

	.progress {
		width: 10vw;
	}

	.loader-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.column {
		width: 30px;
		height: 80px;
		border-radius: 4px;
		margin-left: 10px;
		position: relative;

		.block {
			width: 100%;
			aspect-ratio: 1;
			// height: 30px;
			border-radius: 4px;
			z-index: 5;
			position: absolute;
			bottom: 0px;
		}
	}

	.column:nth-child(1) {
		background-color: #c6d1d4;
		margin-top: 10px;

		.block {
			background-color: #40656e;
			animation: MoveUpDown 1.5s ease-in-out infinite;
		}
	}

	.column:nth-child(2) {
		background-color: #cbf3e1;
		margin-top: 70px;

		.block {
			background-color: #50d799;
			animation: MoveUpDown 1.7s ease-in-out infinite;
		}
	}

	.column:nth-child(3) {
		background-color: #fef5d0;

		.block {
			background-color: #fcdf60;
			animation: MoveUpDown 1.6s ease-in-out infinite;
		}
	}

	.column:nth-child(4) {
		background-color: #ffcbc2;
		margin-top: 45px;

		.block {
			background-color: #ff4f33;
			animation: MoveUpDown 1.4s ease-in-out infinite;
		}
	}

	.status {
		text-align: center;
		opacity: 80%;
		margin-top: 10px;
	}

	&.variant-inline {
		.column {
			width: 0.5ch;
			height: 2ch;

			margin-left: 2px;
		}

		.column:nth-child(1) {
			margin-top: 1px;
		}

		.column:nth-child(2) {
			margin-top: 7px;
		}

		.column:nth-child(4) {
			margin-top: 4px;
		}
	}

	@keyframes MoveUpDown {
		0%,
		100% {
			bottom: 0;
			transform: translateY(0);
		}
		50% {
			bottom: 100%;
			transform: translateY(100%);
		}
	}
}
