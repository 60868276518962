@import "../../../styles/variables.scss";

.item-header-container {
  display: flex;
  gap: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;

  .ui.header {
    margin: 0;
  }
}

.card-item {
  padding: 18px;
  background-color: $card-background;
}
