.group-property-modal {
	.fields-container {
		flex-basis: 33%;
	}

	.mini-table-container {
		flex: 1;

		max-height: 40vh;
		overflow-y: scroll;
	}

	.ui.grid > .row.button-container {
		justify-content: center;
		gap: 1ch;
	}
}

.property-stats {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
}

.property-stats.one {
	margin-top: 20px;
}

.property-stats li {
	width: 50%;
	font-weight: 700;
	list-style: none;
}

.sap-value {
	display: flex;
	gap: 10px;
	align-items: center;
}

.column.fields-container > .field > .ui.input.story-input--md input {
	background: #fafbff;
	border: 2px solid #dbdde4;
	border-radius: 8px;
	margin-top: 4px;
}

.column.fields-container > .field > textarea {
	background: #fafbff;
	border: 2px solid #dbdde4;
	border-radius: 8px;
	margin-top: 4px;
}

.column.mini-table-container > .mini-table thead {
	background-color: #fafbff;
	border: 1px solid #edeef1;
	line-height: 40px;
}

.column.mini-table-container > .mini-table {
	border: 1px solid #edeef1;
}
