

.alert-compenent {
    position: relative;
    display: inline-block;
    :nth-child(1) {
        position: absolute;
        left: 11px;
        top:-5px;

    }
}