@import "../../../styles/variables.scss";
.summary-report-container {
	// display: flex;
	// flex-direction: column;
	// justify-content: space-around;
	// height: 330px;
	display: flex;
	justify-content: stretch;
	color: $dark-blue;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
}
.summary-report-label {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 3px solid $red;
	border-radius: 10px;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 54px;
	height: 80px;
	width: 170px;
	margin-top: 20px;

	color: $dark-blue;
}

.summary-report-img {
	height: auto;
	width: 70%;
	margin: auto;
	display: block;
}

.summary-report-img-background {
	background: $dark-blue;
	padding: 3rem 0;
	border-radius: 6px;
}

.ui.fluid.container.card .summary-report-content {
	margin-top: auto !important;
}
