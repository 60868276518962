@import "../../styles/variables.scss";

.group-page-container {
	display: flex;
	flex-direction: column;
	> .group-page-container-options {
		display: flex;
		width: 100%;
		justify-content: flex-end;
	}
}

.groups-outer-container {
	margin-top: 50px;
}
