.export-from-table
{
	.ui.checkbox
	{
		display: block;
		padding-bottom: .25rem;
	}

	.fields-container
	{
		columns: 3;
	}
}