.job-batches-table .ui.progress
{
	margin-bottom: 2ch;
}

.job-batches-table td.progress
{
	min-width: 12.5vw;
}

.job-batches-table .eye.icon
{
	padding-left: .5rem !important;
	cursor: pointer;
}