.epc-suggestions-modal {
	.will-be-replaced {
		text-decoration: line-through;
	}

	.button-container {
		margin-left: auto;
		margin-right: auto;
	}

	pre {
		white-space: pre-wrap;
	}
}
