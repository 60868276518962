@use "../styles/variables" as *;
@import "../styles/semantic-ui-overrides.scss";

nav#main-nav {
	& {
		height: 100vh;
		display: flex;
    flex-direction: column;
    gap: 6px;
	}

	a {
		display: flex;
	}

	a > button {
		flex: 1;
		color: $dashboard-background;
		border-radius: 48px !important;
		padding: 16px 40px 16px 24px;
	}

	a.active > button {
		background-color: white;
		color: black;

		svg {
			stroke: white;
			fill: black;
			filter: invert(1);
		}
	}

	button {
		text-align: left;
	}

	button.text {
		background-color: transparent;
	}

	// NB: Make Semantic's icons take the same space as our own vector icons
	.button > i.icon {
		margin-right: 1rem;
		width: 24px;
	}
}

.main-layout .header-bar {
	background-color: $dashboard-background;
	display: flex;
	align-items: center;
	padding: 20px;
}

.main-layout main.content {
	background-color: $dashboard-background;
	padding: 20px;
	min-height: calc(100vh - 162px);
	min-width: calc(100vw - 290px);
}

.main-layout .footer {
	text-align: center;
	background-color: $dashboard-background;
	bottom: 0;
	right: 0;
	width: 100%;
}

.main-layout .sidebar {
	flex-direction: column;
	padding: 30px;
	background-color: $primary;
	max-height: 100vh;
	position: sticky;
	display: flex;
	top: 0;
	overflow-y: scroll;
	//background-image: url("../assets/Img/NavDecoration.svg");
	//background-repeat: no-repeat;
	//background-position: bottom;
}

.main-layout .nav-end-container {
	margin-top: auto;
	padding: 1rem 0;
}

.main-layout .arrow-vector-icon {
	display: flex;
	position: relative;

	margin-bottom: 40px;
	width: 40px;
	cursor: pointer;

	&.expand-navbar {
		transform: rotate(-180deg);
		left: 8px;
		transition-property: transform, left;
		transition-duration: 1s;
		transition-timing-function: ease-out;
	}
	&.narrow-navbar {
		transform: rotate(0);
		transition: transform 1s ease-in-out;
		left: 80%;
	}
}

.sidebar-logo-image {
	height: 44px;
	margin-bottom: 7vh;
}

#main-nav.expanded button > svg {
	margin-right: 1rem;
}

#main-nav.collapsed button {
	padding: 16px 24px;
}

.ui.header.version-header {
	color: $text-on-primary;
}

#sidebar.collapsed > div {
	text-align: center;
}
