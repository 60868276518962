.property-details {
	.ui.grid {
		display: flex;
		flex-direction: row;

		& > .row {
			flex-basis: 50%;
		}

		.field {
			display: flex;
			padding-top: 1ch;
			padding-bottom: 1ch;
		}

		.field > .label {
			flex: 1;
			font-weight: bold;
		}
	}

	.epc-ratings {
		font-size: 22px;
		padding-top: 1rem;
		padding-bottom: 1rem;

		.label {
			font-weight: 600;
			padding-right: 1ch;
		}

		&,
		.rating {
			display: flex;
			align-items: center;
		}

		& {
			gap: 2rem;
		}

		.rating.current .value {
			color: #ca3a31;
		}

		.rating.potential .value {
			color: #29854f;
		}

		.value {
			font-size: 40px;
			font-weight: 500;
		}

		button {
			margin-left: auto;
		}
	}

	.ui.divider.details-divide {
		border: 0 !important;
		top: 25%;

		/* Default Semantic Divide splits into two and wants text, so remove this bit */
		&:before {
			display: none;
		}
	}
}
