.custom-improvements-list-item
{
	display: flex !important;
	align-items: center;
	gap: .5rem;

	margin: .5rem 0;
	cursor: pointer;

	.ui.segment
	{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.parameters-and-controls
	{
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: .5rem;
	}

	.parameter-input
	{
		display: flex;
		flex-direction: column;
		white-space: nowrap;

		small {
			color: grey;
			margin-top: .25rem;
		}

		input
		{
			text-align: right;
		}
	}
	
	.label-and-description
	{
		flex: 1;
		padding: 0 .5rem;

		i
		{
			height: auto;
		}

		p
		{
			display: flex;
			align-items: center;
			margin: .5rem 0;
		}

		p:last-child
		{
			margin-bottom: 0;
		}
	}
}