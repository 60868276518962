@import "../../../styles/variables";

.labeltext {
	background-color: transparent !important;
	font-size: 14px !important;
	display: flex !important;
	align-items: center;
	color: $dark-blue !important;
}

.ui.selection.dropdown {
	&.story-select--sm {
		width: 65px;
		min-width: 50px !important;
	}
	&.story-select--md {
		width: 180px;
		min-width: 50px !important;
	}
	&.story-select--lg {
		width: 205px;
		min-width: 50px !important;
	}

	&.story-select--full {
		width: auto !important;
	}
}

.ui.selection.active {
	border: 1px solid $red !important;
}

.ui.selection.active.dropdown:hover .menu {
	border: 1px solid $red;
}

.ui.selection.active.dropdown .menu {
	border: 1px solid $red;
}
