@import "./variables.scss";

.ui.divider,
.ui.card > .ui.divider:last-child {
	width: 46px;
	border: 2px solid $primary !important;
	border-radius: 0 0 0 0 !important;
	background-color: $primary;
}

.ui.input.focus > input,
.ui.input > input:focus {
	:not(.transparent) {
		border-color: $red !important;
	}

	background: #ffffff;
	color: rgba(0, 0, 0, 0.8);
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ui.labeled.input:not([class*="corner labeled"]) .label:first-child + input:focus {
	border-left-color: #85b7d9;
}

.ui[class*="right labeled"].input > input:focus {
	border-right-color: $red !important;
}

/* Input Focus */

.ui.action.input:not([class*="left action"]) > input:focus {
	border-right-color: $red !important;
}

.ui.labeled.input:not([class*="corner labeled"]) .label:first-child + input:focus {
	border-left-color: $red !important;
}

.ui.form textarea:focus {
	color: rgba(0, 0, 0, 0.95);
	border-color: $red !important;
	border-radius: 0.28571429rem;
	background: #ffffff;
	-webkit-box-shadow: 0px 0em 0em 0em rgba(34, 36, 38, 0.35) inset;
	box-shadow: 0px 0em 0em 0em rgba(34, 36, 38, 0.35) inset;
	-webkit-appearance: none;
}

/* Default font */
.ui, .ui.button, .ui.header {
	font-family: $font-family-noto;
}

/*--------------------
        Focus
---------------------*/

.ui.form input:not([type]):focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form input[type="tel"]:focus,
.ui.form input[type="time"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="file"]:focus,
.ui.form input[type="url"]:focus {
	color: rgba(0, 0, 0, 0.95);
	border-color: $red !important;
	border-radius: 0.28571429rem;
	background: #ffffff;
	-webkit-box-shadow: 0px 0em 0em 0em rgba(34, 36, 38, 0.35) inset;
	box-shadow: 0px 0em 0em 0em rgba(34, 36, 38, 0.35) inset;
}

.ui.grey.header {
	color: $grey !important;
}

/*--------------------
        Table
---------------------*/
.ui.table {
	border: none;
	border-bottom: 1px solid $white-border;
	border-radius: 0;
}

.ui.table thead th {
	background-color: $primary;
	color: $text-on-primary;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 22px;
}

.ui.table.secondary-table-theme thead th {
	background-color: $card-background;
	color: $primary-text
}

.ui.table td {
	color: $dark-blue;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
}

.ui.table thead tr:first-child {
	th:first-child, th:last-child {
		border-radius: 0;
	}
}

/*--------------------
        Header
---------------------*/

h1,
h2,
h3,
h4,
h5 {
	color: $dark-blue !important;
}

/*--------------------
        CheckBox
---------------------*/
.ui.checkbox label {
	color: $dark-blue !important;
}

/* Tabs */
.ui.menu.secondary {
	border-bottom: solid $divider-grey 1px;

	a.item, a.item.active {
		border-radius: 0;
		margin-bottom: -2px;

		&:hover {
			background-color: transparent;
		}
	}

	a.item:hover:not(.active) {
		margin-bottom: -4px;
		border-bottom: $divider-grey 2px solid;
	}

	a.item.active {
		font-weight: 600;
		background-color: transparent;
		border-bottom: $primary 2px solid;
	}
}

.ui.segment {
	border: none;
	padding: 2rem 0;
	box-shadow: none;
}

/* Modal */
.ui.modal {
	border-radius: 0;

	> .close-icon {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: black;
		cursor: pointer;
		z-index: 1;
	}

	/* override MCM modal styling */
	top: unset;
	right: unset;
	bottom: unset;
	left: unset;
	overflow: visible;
}

/* dropdown */
.ui.dropdown.visible > .menu {
	border-radius: 0;
	border: 1px solid #CCCCCC;
}

.ui.form .field > label {
	margin-bottom: 0.75rem;
}
