.header-text-container {
	margin-top: 44px;
}

.header-text-container p {
	text-align: left;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #10183d;
	opacity: 0.8;
	margin-bottom: 50px;
}

.header-text-container h1 {
	margin-bottom: 10px !important;
}
