.custom-improvement-presets-list > .item
{
	display: flex !important;
	align-items: center;
}

div.ui.modal.custom-improvement-sets-modal {
	max-width: 1300px;
	width: 80%;
}
