@import "../../../styles/variables";

.ui.input > input:focus {
	border: 1px solid $red;
}

.story-input--sm {
	width: 106px;
}
.story-input--md {
	width: 200px;
}
.story-input--lg {
	width: 250px;
}
