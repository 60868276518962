@import "../../styles/variables.scss";

.result-primary {
  background-color: $primary;
  color: $text-on-primary;
  height: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  .ui.header {
    color: $text-on-primary !important;
  }

  .card-item-content {
    font-size: 2rem;
  }
}

.result-secondary {
  padding: 1.5rem;

  .card-item-content {
    font-size: 1.125rem;
  }
}

.logs-area {
  margin: 2rem 0;

  .smart-target-log {
    margin-bottom: 1rem;
  }
}

.smart-target-result-box-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.result-time-frame {
  font-weight: lighter;
  font-size: 1rem;
  white-space: nowrap;
}

.improvement-container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;

  .smart-target-result-item {
    flex: 1;
    max-width: 50%
  }
}
