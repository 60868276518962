form.import-property-images .dropzone {
	padding: 2rem;
	border: 1px dashed grey;
	margin-bottom: 1rem;
}

.dropzone {
	cursor: pointer;
}

.ui.message ul {
	padding-left: 2rem;
}
