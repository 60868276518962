.property-counter {
	margin-bottom: 6px;
}

.epc-pie-chart-container {
	text-align: center;
	position: relative;
	width: 220px;	// TODO: Lose PX units please
	height: 220px; 
}

.epc-pie-chart-overlay {
	position: absolute;
	top: 0;
	right: 10px;
	bottom: 0;
	left: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 22;
	font-weight: 600;
	color: #10183d;
	text-align: center;
	pointer-events: none;
}

.epc-pie-chart-total-properties-label
{
	font-size: 16px;
	font-weight: 400;
}