#page {
	padding: 50px;
	padding-top: 0px; // NB: Header takes care of this
	padding-bottom: 0px; // No need due to footer now not being sticky
	flex: 1;
	min-width: 0; // NB: Stops the flex child growing with huge horizontal overflow on table pages
}

#page > h1.ui.header {
	margin-top: 44px;
}
