@import "../../../styles/variables.scss";

.datatable-column-cell-td {
	border: 0.1px solid #edeef1a3;
	text-align: center;
	border-spacing: 10px;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	min-width: 60px;

	position: relative;

	.integrity-tile
	{
		position: absolute;
		right: 1ch;
		bottom: 1ch;
	}

	&[data-cell-data-integrity="1"]
	{
		background-color: #e6b8b7;
	}

	&[data-cell-data-integrity="2"]
	{
		background-color: #c6efce;
	}

	&[data-cell-data-integrity="3"]
	{
		background-color: #ffeb9c;
	}

	&[data-cell-data-integrity="4"]
	{
		background-color: #95b3d7;
	}

	&[data-cell-data-integrity="5"]
	{
		background-color: #ffffff;
	}
}

.datatable-column-cell-epc {
	display: flex;
	justify-content: center;
}
