$green: #78d59f;
$dark-green: #427758;
$red: #ff5f43;
$dark-red: #ca3a31;
$dark-blue: #10183d;
$grey: #9395a2;
$dark-orange: #d95139;

$complete: #E2FBE8;
$complete-text: #30613C;
$error: #FDE8D9;
$error-text: #BF2E2E;
$progress: #D7F8FD;
$progress-text: #1C4C61;

$epc-rating-grade-a: #43936c;
$epc-rating-grade-b: #6acf9d;
$epc-rating-grade-c: #8de3ba;
$epc-rating-grade-d: #f2c04b;
$epc-rating-grade-e: #ebaf64;
$epc-rating-grade-f: #ca3a31;
$epc-rating-grade-g: #752522;
$epc-rating-ungraded: $grey;

$semantic-ui-grey: #767676;
$dashboard-background: #fff;
$card-background: #F4F4F4;
$divider-grey: #DBDBDB;
$white-border: #E6E6E6;

$rounded-border-small: 10px;
$rounded-border-large: 16px;

$font-family-noto: "Noto Sans", Arial, sans-serif;

$mears-red: #DB042C;

$primary: $dark-blue;
$text-on-primary: #fff;

$primary-text: #000;
$secondary-text: #515151;
