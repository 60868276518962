@import "../../../styles/variables";

.ui.segment.filter-item-upper,
.ui.segment.filter-item-lower {
	border-radius: 4px;
	padding: 10px 20px 14px 12px;
	display: flex;
	min-height: 2.8rem;
	font-size: 0.875rem;
	margin: 0;
	align-items: center;

	.checkbox {
		margin: 0 2rem 0 1rem;
	}
}

.ui.segment.filter-item-selected {
	background-color: $card-background;
}

.ui.segment.filter-item-upper {
	height: 2.8rem;

	&:hover {
		cursor: pointer;
	}
}

.ui.segment.filter-item-lower {
	background-color: $grey;
	margin-top: 0;

	.ui.selection {
		min-width: 4rem;
		width: 4rem;
		min-height: 1.5rem;
		margin: 0 1rem;

		.text {
			line-height: 0;
			position: absolute;
			top: 50%;
		}

		.icon {
			margin: 0;
			padding: 0;
			top: 0.4rem;
		}
	}

	.ui.input {
		margin: 0 1rem;
		width: 6rem;
		height: 1.8rem;

		input {
			text-align: center;
		}
	}
}

.filter-item-collapsed > .ui.segment.filter-item-lower {
	display: none;
}

.filter-item-expanded {
	.ui.segment.filter-item-upper {
		border-radius: 4px 4px 0 0;
	}

	.ui.segment.filter-item-lower {
		display: flex;
		border-radius: 0 0 4px 4px;
		justify-content: space-evenly;
	}
}

.filter-item-connective {
	width: 2.5rem;
}

.float-right-container {
	margin-left: auto;
	display: flex;
	gap: 0.2rem;
	align-items: center;

	.ui.button.underline {
		height: 1rem;
		padding: 1rem;
		top: 50%;
		z-index: 100;
	}

	.ui.checkbox {
		> label {
			padding: 0 0 0 4.5rem;
		}
	}

	& .settings-slider-padding {
		padding-right: 3.3rem;
	}
}
