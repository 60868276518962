@import "../../../styles/variables";

.header-component {
	width: 100%;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $dashboard-background;
	padding: 30px 80px;
	position: sticky;
	top: 0;
	z-index: 10;
}
