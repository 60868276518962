@import "../../../styles/variables.scss";

.color-card-thermal {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;

	> .container.card {
		background-color: $red;
		color: white;
	}

	> .container.card h2 {
		color: white !important;
	}

	> div {

		> .ui.divider {
			border: 2px solid white !important;
			background-color: white;
		}

		> div {
			> div {
				> div {
					> .ui.button {
						background-color: white;
						color: #10183d;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
					}
				}
			}
		}

	}
}

.thermal-square-opaque {
	background-color: rgba(255, 255, 255, 0.293);
	width: 120px;
	height: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
}
