@import "../../../styles/variables";

button.ui.button {
	display: flex;
	padding: 16px 24px 16px 24px;
	height: 57px;
	align-items: center;
	transition: background-color 0.2s, color 0.2s;
	font-weight: 600;

	&.mini
	{
		padding: .5rem;
		height: auto !important;

		.icon
		{
			margin: 0 !important;
		}
	}

	svg {
		stroke: rgba(255, 255, 255, 0.797);
		// min-width: 24px;
		// min-height: 24px;
	}
	.text-export {
		width: 85px;
		font-weight: 600;
		font-size: 14px;
		line-height: 19px;
	}
}
.text-hidden {
	display: none;
}

.text-show {
	margin-left: 40px;
	min-width: 100px;
}

button.ui.button,
.ui.card > .button:last-child {
	border-radius: 32px;
	padding-top: 19px;
	padding-bottom: 19px;
}

button.ui.button.contained {
	background-color: $primary;
	color: white;
	svg {
		stroke: white;
	}

	&:hover {
		opacity: 0.7;
	}

	&.inverse {
		background-color: white;
		color: black;
	}
}

button.ui.button.menu {
	padding: 20px 18px;
}

button.ui.button.outlined {
	border: 2px solid $dark-blue;
	color: $dark-blue;
	background: transparent;
	display: flex;
	gap: 15px;

	&:hover {
		background-color: $dark-blue;
		color: white;
	}
}

button.ui.button.outlined-borderless {
	color: $red;
	background-color: $dashboard-background;
}

button.ui.button a {
	color: white;
}

button.ui.button.text,
.ui.button.text:hover {
	background-color: transparent;
}

button.ui.button.text a {
	color: white;
	opacity: 0.5;
}

button.pagination-container .ui.button.text {
	white-space: nowrap;
}

button.inline-buttons {
	display: flex;
	gap: 1rem;
}

button.ui.button.underline {
	color: #ff5f43;
	background-color: transparent;
	text-decoration: underline;
	font-size: 16px;
	padding: 0px;
	padding-left: 10px;
}

button.ui.button.outlined svg {
	stroke: black;
	transition: stroke 0.2s;
}

button.ui.button.outlined:hover svg {
	stroke: white;
}

button.ui.button.modal-primary, button.ui.modal-secondary {
	border-radius: 6px;
	padding: 10px 18px 10px 18px;
	width: 100%;
	text-align: center;
	justify-content: center;
	height: 2.5rem;
}

button.ui.button.modal-primary {
	background-color: black;
	color: white;
}

button.ui.button.modal-secondary {
	background-color: white;
	border: solid 1.5px $white-border;
}
