@import "../../../styles/variables";

div.container.card {
	background: $card-background;
	padding: 30px 50px;
	border-radius: 0;
	box-shadow: none;
	margin: 1.25rem;

	.header-container {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.header-container i {
		cursor: pointer;
	}

	.control-buttons {
		font-size: 1rem;
		margin-left: 12px;
	}

	.control-buttons > * {
		margin-left: 8px;
	}
}

div.container.card > h2 {
	margin-bottom: 13px;
}

div.container.card > h2 + .divider {
	margin-top: 0px;
}

.ui.stackable.two.column.grid > .four.wide.column {
	display: flex;
}

.ui.stackable.two.column.grid > .seven.wide.column {
	display: flex;
}

.ui.stackable.two.column.grid > .twelve.wide.column {
	display: flex;
}

div.container.card.modal-card {
	background-color: white;

	.ui.divider {
		margin: 0 0 2rem 0;
	}
}

div.container.card.modal-nested {
	//border-radius: 16px;
	//padding: 24px;
	//width: 600px;
	//height: 480px;
	//margin-top: 0;
	//
	//.header-container > .ui.header {
	//	font-size: 1.25rem;
	//}
}
